<template>
  <div>
    <v-form v-if="type" ref="form" lazy-validation>
      <component
        :id="id"
        :is="componentName"
        :formData="formData"
        :fileData="fileData"
        :iconImgData="iconImg"
        :smallImgData="smallImg"
        :largeImgData="largeImg"
        :videoData="video"
        :isAvailable="isAvailable"
        :userIdItems="userIdItems"
        :isCheckLocale="isCheckLocale"
        :isFileError="isFileError"
        :isFileLargeError="isFileLargeError"
        :token="token"
        :basicUrl="basicUrl"
        :isShowDaastVast="permission.addMarkup"
        :isViewModerated="isViewModerated"
        @loadFile="loadFileParams"
      />
      <CreativeTable
        v-if="type === 'NATIVE'"
        class="mt-20 mb-30"
        title="table.dataTypesTitle"
        :items="dataTypes"
        :nameItems="dataTypesItems"
        :isViewModerated="isViewModerated"
        :type="type"
      />
      <CreativeTable
        class="my-20"
        :items="userTrackers"
        :nameItems="nameItems"
        :isShowAddBtn="isShowTrackersAddBtn"
        :isViewModerated="isViewModerated"
        :type="type"
      />
      <div class="d-sm-flex justify-space-between">
        <ActionButton outlined color="error" class="mb-5 mb-sm-0" @click="backHandler">
          {{ $t('button.back') }}
        </ActionButton>
        <div v-if="permission.update || permission.moderate" class="d-sm-flex align-end">
          <ActionButton v-if="!isViewModerated" outlined :loading="actionLoading" color="green" @click="save">
            {{ $t('button.save') }}
          </ActionButton>
          <ActionButton v-else outlined :loading="actionLoading" color="green" @click="showActionDialog">
            {{ $t('button.moderate') }}
          </ActionButton>
        </div>
      </div>
    </v-form>

    <CustomDialog v-model="isActionDialog" minHeight="250">
      <v-form ref="formDialog" class="text-center mt-15">
        <CustomCheckbox
          v-model="moderateParams.isModerated"
          hide-details
          class="mb-10"
          :label="$t('label.moderate')"
        />
        <CustomInput
          v-model.trim="moderateParams.moderationMsg"
          v-if="!moderateParams.isModerated"
          :placeholder="$t('placeholder.enterReason')"
          :rules="[(v) => !!v || '']"
        />
        <ActionButton class="my-15" :loading="actionLoading" @click="moderate">
          {{ $t('button.confirm') }}
        </ActionButton>
      </v-form>
    </CustomDialog>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'
import {backHandler} from '@/helpers/handler/backHandler'
import {getItem} from '@/helpers/persistantStorage'

import BannerType from '@/views/creatives/type/BannerType'
import AudioType from '@/views/creatives/type/AudioType'
import VideoType from '@/views/creatives/type/VideoType'
import NativeType from '@/views/creatives/type/NativeType'
import CreativeTable from '@/views/creatives/part/CreativeTable'
import {NAME_BANNER_ITEMS} from '@/helpers/variables/creatives'

export default {
  name: 'CreativeDetail',
  components: {BannerType, AudioType, VideoType, NativeType, CreativeTable},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    isValid: false,
    isFileError: false,
    isFileLargeError: false,
    formData: {},
    fileType: null,
    fileData: {},
    iconImg: {},
    smallImg: {},
    largeImg: {},
    video: {},
    userTrackers: [],
    dataTypes: [],
    dataTypesItems: [],
    maxTrackersLength: null,
    nameItems: [],
    type: null,
    componentName: null,
    isViewModerated: false,
    isActionDialog: false,
    moderateParams: {
      isModerated: true,
      moderationMsg: null,
    },
  }),
  computed: {
    ...mapState({
      item: (state) => state.creative.item,
      actionLoading: (state) => state.creative.actionLoading,
      isCheckLocale: (state) => state.isCheckLocale,
      userIdItems: (state) => state.user.userIdItems,
      token: (state) => state.auth.token,
      basicUrl: (state) => state.auth.basicUrl,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiserManager: 'auth/isAdvertiserManager',
      permission: 'creative/permission',
    }),
    isAvailable() {
      return this.isAdmin || this.isManager || this.isAdvertiserManager
    },
    isShowTrackersAddBtn() {
      return this.maxTrackersLength !== null ? this.userTrackers.length < this.maxTrackersLength : true
    },
  },
  async mounted() {
    await this.setTypeName()
    if (this.id) {
      await this.loadData()
      this.isViewModerated = JSON.parse(getItem('nt_creative_moderated'))
    }
    if (this.isAvailable) await this.loadClients()
    await this.loadNameItems()
  },
  methods: {
    ...mapActions({
      getClients: 'user/GET_CLIENTS',
      createCreative: 'creative/CREATE_CREATIVE',
      editCreative: 'creative/EDIT_CREATIVE',
      getTrackers: 'dictionary/CREATIVE_TRACKERS',
      getDataTypes: 'dictionary/CREATIVE_DATA_TYPES',
      getItemById: 'creative/GET_ITEM_BY_ID',
      moderateCreative: 'creative/MODERATE_CREATIVE',
    }),
    backHandler,
    setTypeName() {
      this.type = getItem('nt_creative_type')
      this.componentName = this.type.toLowerCase().replace(/(^\w)|(\s+\w)/g, (a) => a.toUpperCase()) + 'Type'
    },
    async loadData() {
      await this.getItemById(this.id)
      await this.setCreativeData()
    },
    setCreativeData() {
      const creativeTypeName = {
        BANNER: 'bannerAds',
        AUDIO: 'audioAds',
        VIDEO: 'videoAds',
        NATIVE: 'nativeAds',
      }
      this.fileType = 'fileData'
      const {userId, name, status, type, isModerated} = this.item
      const creativeType = this.item[creativeTypeName[this.type]]

      const {
        finalUrl,
        displayUrl,
        daast,
        sequence,
        vast,
        filename,
        h,
        mimeType,
        fileType,
        w,
        bitrate,
        duration,
        userClickTrackTagUrl,
        userViewTrackPixelUrl,
        userTrackers,
        bigTitle,
        ctaText,
        desc,
        mediumTitle,
        smallTitle,
        sponsored,
        data,
        iconImg,
        smallImg,
        largeImg,
        video,
      } = creativeType

      this.formData = {
        userId,
        name,
        status,
        type,
        isModerated,
        finalUrl,
        displayUrl: displayUrl ?? '',
        daast,
        sequence,
        vast,
        bigTitle,
        ctaText,
        desc,
        mediumTitle,
        smallTitle,
        sponsored,
      }

      this.fileData = {
        filename,
        h,
        mimeType,
        type: fileType,
        w,
        bitrate,
        duration,
      }

      switch (this.type) {
        case 'BANNER':
          if (userClickTrackTagUrl) {
            this.userTrackers.push({
              id: this.$uuidv4(),
              name: NAME_BANNER_ITEMS[0].text,
              url: userClickTrackTagUrl,
            })
          }
          if (userViewTrackPixelUrl) {
            this.userTrackers.push({
              id: this.$uuidv4(),
              name: NAME_BANNER_ITEMS[1].text,
              url: userViewTrackPixelUrl,
            })
          }
          break
        case 'AUDIO':
        case 'VIDEO':
          this.userTrackers = userTrackers.map(({tracker, url}) => ({
            id: this.$uuidv4(),
            name: tracker,
            url,
          }))
          break
        case 'NATIVE':
          this.iconImg = iconImg
          this.smallImg = smallImg
          this.largeImg = largeImg
          this.video = video
          this.fileType = 'iconImg'
          this.userTrackers = userTrackers.map(({tracker, url}) => ({
            id: this.$uuidv4(),
            name: tracker,
            url,
          }))
          this.dataTypes = data.map(({type, value}) => ({
            id: this.$uuidv4(),
            name: type,
            url: value,
          }))
          break
        default:
          break
      }
    },
    async loadClients() {
      await this.getClients()
    },
    async loadNameItems() {
      switch (this.type) {
        case 'BANNER':
          this.nameItems = NAME_BANNER_ITEMS
          this.maxTrackersLength = 2
          break
        case 'AUDIO':
        case 'VIDEO':
          this.nameItems = await this.getTrackers(this.type)
          break
        case 'NATIVE':
          this.nameItems = await this.getTrackers(this.type)
          this.dataTypesItems = await this.getDataTypes()
          break
        default:
          this.nameItems = []
      }
    },
    async save() {
      await this.checkImgError(this.fileType, true)
      const isFileError =
        this.type === 'NATIVE' ? !this.isFileError && !this.isFileLargeError : !this.isFileError
      if (this.$refs.form.validate() && isFileError) {
        const formData = await this.parseFormData(
          this.type,
          this.formData,
          this.fileData,
          this.iconImg,
          this.smallImg,
          this.largeImg,
          this.video,
          this.userTrackers,
          this.dataTypes
        )
        const resp = this.id
          ? await this.editCreative({id: this.id, formData: formData})
          : await this.createCreative(formData)
        if (resp?.status === 200) await this.backHandler()
      }
    },
    parseFormData(creativeType, data, fileData, iconImg, smallImg, largeImg, video, userTrackers, dataTypes) {
      const {
        userId,
        name,
        displayUrl,
        finalUrl,
        daast,
        vast,
        sequence,
        smallTitle,
        mediumTitle,
        bigTitle,
        desc,
        ctaText,
        sponsored,
        isModerated,
      } = data
      switch (creativeType) {
        case 'BANNER':
          return {
            userId,
            name,
            type: creativeType,
            isModerated,
            bannerAds: {
              displayUrl,
              finalUrl,
              ...fileData,
              userClickTrackTagUrl: userTrackers?.find((a) => a.name === NAME_BANNER_ITEMS[0].text)?.url,
              userViewTrackPixelUrl: userTrackers?.find((a) => a.name === NAME_BANNER_ITEMS[1].text)?.url,
            },
          }
        case 'AUDIO':
          return {
            userId,
            name,
            type: creativeType,
            isModerated,
            audioAds: {
              daast,
              displayUrl,
              finalUrl,
              ...fileData,
              userTrackers: userTrackers.map(({name, url}) => ({tracker: name, url})),
            },
          }
        case 'VIDEO':
          return {
            userId,
            name,
            type: creativeType,
            isModerated,
            videoAds: {
              displayUrl,
              finalUrl,
              sequence,
              vast,
              ...fileData,
              userTrackers: userTrackers.map(({name, url}) => ({tracker: name, url})),
            },
          }
        case 'NATIVE':
          return {
            userId,
            name,
            type: creativeType,
            isModerated,
            nativeAds: {
              bigTitle,
              ctaText,
              data: dataTypes.map(({name, url}) => ({type: name, value: url})),
              desc,
              displayUrl,
              finalUrl,
              iconImg,
              largeImg,
              mediumTitle,
              smallImg,
              smallTitle,
              sponsored,
              userTrackers: userTrackers.map(({name, url}) => ({tracker: name, url})),
              video,
            },
          }
        default:
          return {}
      }
    },
    checkImgError(type, withLarge) {
      this.isFileError = !this[type]?.filename
      if (withLarge) this.isFileLargeError = !this.largeImg.filename
    },
    loadFileParams(file, type) {
      this.fileType = type
      this[type] = file
      this.checkImgError(type)
    },
    showActionDialog() {
      this.isActionDialog = true
    },
    async moderate() {
      if (this.$refs.formDialog.validate()) {
        const resp = await this.moderateCreative({id: this.id, params: this.moderateParams})
        if (resp?.status === 200) await this.backHandler()
      }
    },
  },
}
</script>

<template>
  <div class="align-self-start mt-5">
    <p>
      <span class="font-weight-bold">{{ $t('type.name') }}:</span> {{ media.name }}
    </p>
    <p>
      <span class="font-weight-bold">{{ $t('type.type') }}:</span> {{ media.type }}
    </p>
    <p>
      <template v-if="media.duration">
        <span class="font-weight-bold">{{ $t('type.duration') }}:</span> {{ media.duration }}
      </template>
      <template v-else>
        <span class="font-weight-bold">{{ $t('type.width') }} х {{ $t('type.height') }}:</span>
        {{ media.width }} х {{ media.height }}
      </template>
    </p>
  </div>
</template>
<script>
export default {
  name: 'CreativeItemInfo',
  props: {
    media: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

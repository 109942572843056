<template>
  <div>
    <h4 class="text-center mb-5">{{ $t(title) }}</h4>
    <CustomTable
      v-model="selected"
      :headers="headers"
      :items="items"
      :show-select="!isViewModerated"
      item-key="id"
      disable-sort
    >
      <template v-if="!isViewModerated" #top>
        <v-toolbar flat>
          <v-btn v-if="isShowAddBtn" color="blue" dark class="mr-5" @click="dialog = true">
            {{ $t('button.new') }}
          </v-btn>
          <v-btn
            color="red"
            :dark="!!selected.length"
            :disabled="!selected.length"
            @click="dialogDelete = true"
          >
            {{ $t('button.delete') }}
          </v-btn>
        </v-toolbar>
      </template>

      <template #item.details="{item}">
        <v-hover v-slot="{hover}" class="ml-5">
          <v-icon :disabled="isViewModerated" :color="hover ? 'red' : ''" size="20" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </v-hover>
      </template>
    </CustomTable>

    <CustomDialog v-model="dialog" max-width="600px" minHeight="auto" :isShowClose="false">
      <div class="pa-10">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <CustomSelect
                v-model="editedItem.name"
                :items="nameItems"
                :rules="rules"
                :label="$t('label.name')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <CustomInput v-model="editedItem.url" :rules="rules" :label="$t('label.url')" />
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">{{ $t('button.discard') }}</v-btn>
          <v-btn color="blue darken-1" text @click="save">{{ $t('button.save') }}</v-btn>
        </v-card-actions>
      </div>
    </CustomDialog>

    <CustomDialog v-model="dialogDelete" minHeight="250">
      <div class="text-center mt-15">
        <h3>{{ $t('dialog.deleteTitle') }}</h3>
        <ActionButton class="my-15" @click="deleteItems">
          {{ $t('button.confirm') }}
        </ActionButton>
      </div>
    </CustomDialog>
  </div>
</template>
<script>
export default {
  name: 'CreativeTable',
  props: {
    title: {
      type: String,
      default: 'table.trackersTitle',
    },
    items: {
      type: Array,
      default: () => [],
    },
    nameItems: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    isShowAddBtn: {
      type: Boolean,
      default: true,
    },
    isViewModerated: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: [],
    dialog: false,
    dialogDelete: false,
    rules: [(v) => !!v || ''],
    editedIndex: -1,
    editedItem: {
      name: '',
      url: '',
    },
    defaultItem: {
      name: '',
      url: '',
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('table.name'),
          value: 'name',
          align: 'center',
        },
        {
          text: this.$t('table.url'),
          value: 'url',
          align: 'center',
        },
        {
          text: this.$t('table.details'),
          value: 'details',
          align: 'end',
        },
      ]
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItems() {
      this.selected.forEach((item) => {
        this.editedIndex = this.items.indexOf(item)
        this.items.splice(this.editedIndex, 1)
      })
      this.selected = []
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$refs.form.resetValidation()
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.$refs.form.validate()) {
        this.editedItem = {
          ...this.editedItem,
          id: this.$uuidv4(),
        }
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem)
        } else {
          this.items.push(this.editedItem)
        }
        this.close()
      }
    },
  },
}
</script>

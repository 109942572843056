<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="12" md="5" lg="5" xl="4" class="mb-5 mb-md-0">
        <CustomSelect
          v-model="formData.userId"
          v-if="isAvailable"
          :label="$t('label.userId')"
          :rules="mapRules('userIdRequired')"
          :items="userIdItems"
          :readonly="isReadonlyWithId || isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.name"
          :label="$t('label.name')"
          :rules="mapRules('nameRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <!--        TODO: подставить маску типо -->
        <!--        v-mask="'http (___) ___ __ __'"-->
        <CustomInput
          v-model.trim="formData.finalUrl"
          :label="$t('label.finalUrl')"
          :rules="mapRules('finalUrlRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.displayUrl"
          :label="$t('label.displayUrl')"
          :rules="mapRules('displayUrlRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.smallTitle"
          :label="$t('label.smallTitle')"
          :rules="mapRules('required')"
          :readonly="isReadonly"
          maxlength="25"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.mediumTitle"
          :label="$t('label.mediumTitle')"
          :readonly="isReadonly"
          maxlength="90"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.bigTitle"
          :label="$t('label.bigTitle')"
          :readonly="isReadonly"
          maxlength="140"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.desc"
          :label="$t('label.desc')"
          :rules="mapRules('required')"
          :readonly="isReadonly"
          maxlength="140"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.ctaText"
          :label="$t('label.ctaText')"
          :rules="mapRules('required')"
          :readonly="isReadonly"
          maxlength="15"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.sponsored"
          :label="$t('label.sponsored')"
          :rules="mapRules('required')"
          :readonly="isReadonly"
          maxlength="25"
          class="mb-5"
        />
        <div class="mb-15">
          <FileUploader
            :label="iconImgData.filename ? iconImgData.filename : 'label.fileIcon'"
            :class="isFileError ? 'file-error' : ''"
            :accepted-file-types="acceptedTypeImage"
            :disabled="isReadonly"
            type="native/image/icon"
            view="iconImg"
            @uploaded="loadFileParams"
          />
          <div class="mt-5 font-14">
            <p>{{ $t('type.width') }} х {{ $t('type.height') }}: 627x627, 836x627, 1198x627</p>
            <p>{{ $t('type.type') }}: .jpg, .jpeg, .png</p>
          </div>
        </div>
        <div class="mb-15">
          <FileUploader
            :label="smallImgData.filename ? smallImgData.filename : 'label.fileSmallImg'"
            :accepted-file-types="acceptedTypeImage"
            :disabled="isReadonly"
            type="native/image/small"
            view="smallImg"
            @uploaded="loadFileParams"
          />
          <div class="mt-5 font-14">
            <p>{{ $t('type.width') }} х {{ $t('type.height') }}: 200x200, 267x200, 382x200</p>
            <p>{{ $t('type.type') }}: .jpg, .jpeg, .png</p>
          </div>
        </div>
        <div class="mb-15">
          <FileUploader
            :label="largeImgData.filename ? largeImgData.filename : 'label.fileLargeImg'"
            :class="isFileLargeError ? 'file-error' : ''"
            :accepted-file-types="acceptedTypeImage"
            :disabled="isReadonly"
            type="native/image/large"
            view="largeImg"
            @uploaded="loadFileParams"
          />
          <div class="mt-5 font-14">
            <p>{{ $t('type.width') }} х {{ $t('type.height') }}: 50x50</p>
            <p>{{ $t('type.type') }}: .jpg, .jpeg, .png</p>
          </div>
        </div>
        <div class="mb-15">
          <FileUploader
            :label="videoData.filename ? videoData.filename : 'label.fileVideo'"
            :accepted-file-types="acceptedTypeVideo"
            :disabled="isReadonly"
            type="native/video"
            view="video"
            @uploaded="loadFileParams"
          />
          <div class="mt-5 font-14">
            <p>{{ $t('type.size') }}: 1 Gb</p>
            <p>{{ $t('type.type') }}: .mp4, .webm, .mov, .avi</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="7">
        <v-row class="mb-10">
          <v-col cols="12" sm="6">
            <div class="d-flex align-center mb-5">
              <v-img
                contain
                max-width="100"
                max-height="100"
                :src="iconImg ? iconImg.src : require(`@/assets/images/${defaultSrc}`)"
              />
              <v-sheet rounded="10" outlined class="ml-5 pa-5">{{ sponsoredValue }}</v-sheet>
            </div>
            <div class="native-image-block mb-5">
              <v-img
                contain
                max-height="250"
                :src="mediaBigFirstValue ? mediaBigFirstValue : require(`@/assets/images/${defaultSrc}`)"
              />
            </div>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ titleMediumFirstValue }}</v-sheet>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ descValue }}</v-sheet>
            <div class="d-flex align-center justify-space-between mb-5">
              <v-sheet class="pa-5" outlined rounded="10">{{ displayUrlValue }}</v-sheet>
              <ActionButton outlined>{{ ctaValue }}</ActionButton>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="native-image-block mb-5">
              <v-img
                contain
                max-height="250"
                :src="mediaBigFirstValue ? mediaBigFirstValue : require(`@/assets/images/${defaultSrc}`)"
              />
            </div>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ titleBigFirstValue }}</v-sheet>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ descValue }}</v-sheet>
            <div class="d-flex align-center justify-space-between mb-5">
              <v-img
                contain
                max-width="100"
                max-height="100"
                :src="iconImg ? iconImg.src : require(`@/assets/images/${defaultSrc}`)"
              />
              <ActionButton outlined>{{ ctaValue }}</ActionButton>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12">
            <v-row>
              <v-col cols="7">
                <div class="native-image-block">
                  <v-img
                    contain
                    max-height="250"
                    :src="
                      mediaSmallFirstValue ? mediaSmallFirstValue : require(`@/assets/images/${defaultSrc}`)
                    "
                  />
                </div>
              </v-col>
              <v-col cols="4" class="ml-auto">
                <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ titleSmallValue }}</v-sheet>
                <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ descValue }}</v-sheet>
                <div class="d-flex align-center justify-space-between mb-5">
                  <v-sheet class="pa-5" outlined rounded="10">{{ displayUrlValue }}</v-sheet>
                  <ActionButton outlined>{{ ctaValue }}</ActionButton>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" sm="6">
            <div class="d-flex align-center mb-5">
              <v-img
                contain
                max-width="100"
                max-height="100"
                :src="iconImg ? iconImg.src : require(`@/assets/images/${defaultSrc}`)"
              />
              <v-sheet rounded="10" outlined class="ml-5 pa-5">{{ sponsoredValue }}</v-sheet>
            </div>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ titleMediumFirstValue }}</v-sheet>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ descValue }}</v-sheet>
            <div class="native-image-block mb-5">
              <v-img
                contain
                max-height="250"
                :src="mediaBigFirstValue ? mediaBigFirstValue : require(`@/assets/images/${defaultSrc}`)"
              />
            </div>
            <div class="d-flex align-center justify-space-between mb-5">
              <v-sheet class="pa-5" outlined rounded="10">{{ displayUrlValue }}</v-sheet>
              <ActionButton outlined>{{ ctaValue }}</ActionButton>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="native-image-block mb-5">
              <div v-if="!video" class="text-center">
                <v-img
                  contain
                  max-height="250"
                  :src="mediaBigFirstValue ? mediaBigFirstValue : require(`@/assets/images/${defaultSrc}`)"
                  class="mb-5"
                />
              </div>
              <video v-else preload controls :width="'100%'" :height="250">
                <source :src="video.src" type="video/mp4" />
              </video>
            </div>
            <v-sheet class="pa-5 mb-5" outlined rounded="10">{{ titleBigFirstValue }}</v-sheet>
            <div class="d-flex align-center justify-space-between mb-5">
              <v-sheet class="pa-5" outlined rounded="10">{{ displayUrlValue }}</v-sheet>
              <ActionButton outlined>{{ ctaValue }}</ActionButton>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {httpRegular} from '@/helpers/variables/expression'

export default {
  name: 'NativeType',
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    iconImgData: {
      type: Object,
      default: () => {},
    },
    smallImgData: {
      type: Object,
      default: () => {},
    },
    largeImgData: {
      type: Object,
      default: () => {},
    },
    videoData: {
      type: Object,
      default: () => {},
    },
    isAvailable: {
      type: Boolean,
      default: false,
    },
    userIdItems: {
      type: Array,
      default: () => [],
    },
    isCheckLocale: {
      type: Boolean,
      default: false,
    },
    isFileError: {
      type: Boolean,
      default: false,
    },
    isFileLargeError: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
    basicUrl: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: 'preview.png',
    },
    isViewModerated: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    iconImg: null,
    smallImg: null,
    largeImg: null,
    video: null,
    acceptedTypeImage: 'image/jpeg, image/jpg, image/png',
    acceptedTypeVideo: 'video/mp4, video/webm, video/mov, video/avi',
  }),
  computed: {
    isReadonlyWithId() {
      return !!this.id
    },
    isReadonly() {
      return this.isViewModerated
    },
    sponsoredValue() {
      return this.formData.sponsored || this.$t('label.sponsored')
    },
    titleMediumFirstValue() {
      return (
        this.formData.mediumTitle ||
        this.formData.smallTitle ||
        this.$t('label.mediumTitle') + ' / ' + this.$t('label.smallTitle')
      )
    },
    titleBigFirstValue() {
      return (
        this.formData.bigTitle ||
        this.formData.mediumTitle ||
        this.formData.smallTitle ||
        this.$t('label.bigTitle') + ' / ' + this.$t('label.mediumTitle') + ' / ' + this.$t('label.smallTitle')
      )
    },
    titleSmallValue() {
      return this.formData.smallTitle || this.$t('label.smallTitle')
    },
    descValue() {
      return this.formData.desc || this.$t('label.desc')
    },
    ctaValue() {
      return this.formData.ctaText || this.$t('label.ctaText')
    },
    displayUrlValue() {
      return this.formData.displayUrl || this.$t('label.displayUrl')
    },
    mediaBigFirstValue() {
      return this.largeImg?.src || this.smallImg?.src
    },
    mediaSmallFirstValue() {
      return this.smallImg?.src || this.largeImg?.src
    },
  },
  watch: {
    iconImgData: {
      deep: true,
      handler(data) {
        this.setFileParams(data, 'iconImg')
      },
    },
    smallImgData: {
      deep: true,
      handler(data) {
        this.setFileParams(data, 'smallImg')
      },
    },
    largeImgData: {
      deep: true,
      handler(data) {
        this.setFileParams(data, 'largeImg')
      },
    },
    videoData: {
      deep: true,
      handler(data) {
        this.setFileParams(data, 'video')
      },
    },
  },
  methods: {
    mapRules(name) {
      switch (name) {
        case 'finalUrlRequired':
          return this.isCheckLocale
            ? [
                (v) => !!v || this.$t('rules.finalUrlRequired'),
                (v) => httpRegular.test(v) || this.$t('rules.finalUrlStart'),
              ]
            : []
        default:
          return this.isCheckLocale ? [(v) => !!v || this.$t(`rules.${name}`)] : []
      }
    },
    setFileParams(file, type) {
      const {filename, h, w} = file
      const viewType =
        type === 'video' ? `/file/creative/native/video/stream/` : `/file/creative/native/image/view/`
      if (filename) {
        this[type] = {
          w,
          h,
          src: `${this.basicUrl}${viewType}${this.token}/${filename}`,
        }
      } else {
        this[type] = null
      }
    },
    loadFileParams(file, type) {
      this.$emit('loadFile', file, type)
    },
  },
}
</script>
<style lang="scss">
.native-image-block {
  background: #eff0f2;
}
</style>

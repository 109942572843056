<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="12" md="5" lg="5" xl="4" class="mb-5 mb-md-0">
        <CustomSelect
          v-model="formData.userId"
          v-if="isAvailable"
          :label="$t('label.userId')"
          :rules="mapRules('userIdRequired')"
          :items="userIdItems"
          :readonly="isReadonlyWithId || isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.name"
          :label="$t('label.name')"
          :rules="mapRules('nameRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.finalUrl"
          :label="$t('label.finalUrl')"
          :rules="mapRules('finalUrlRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.displayUrl"
          :label="$t('label.displayUrl')"
          :rules="mapRules('displayUrlRequired')"
          :readonly="isReadonly"
          class="mb-5"
        />
        <CustomInput
          v-model.trim="formData.sequence"
          :label="$t('label.sequence')"
          :readonly="isReadonly"
          class="mb-5"
          @keypress="onlyNumbers"
        />
        <FileUploader
          :label="fileData.filename ? fileData.filename : 'label.fileName'"
          :class="isFileError ? 'file-error' : ''"
          type="video"
          :accepted-file-types="acceptedType"
          :disabled="isReadonly"
          @uploaded="loadFileParams"
        />
        <div class="mt-5 font-14" :class="{'mb-15': isShowDaastVast}">
          <p>{{ $t('type.size') }}: 100 Mb</p>
          <p>{{ $t('type.type') }}: .mp4, .webm, .mov, .flv, .avi</p>
        </div>
        <CustomTextarea
          v-model.trim="formData.vast"
          v-if="isShowDaastVast"
          :label="$t('label.vast')"
          :readonly="isReadonly"
        />
      </v-col>
      <v-col cols="12" md="6">
        <div v-if="!media" class="text-center">
          <v-img contain max-height="400" :src="require(`@/assets/images/${defaultSrc}`)" />
        </div>
        <template v-else>
          <CreativeItemInfo class="mb-10" :media="media" />
          <video preload controls :width="'100%'" :height="400">
            <source :src="media.src" type="video/mp4" />
          </video>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CreativeItemInfo from '@/views/creatives/part/CreativeItemInfo'
import {httpRegular} from '@/helpers/variables/expression'
import onlyNumbers from '@/helpers/onlyNumbers'

export default {
  name: 'VideoType',
  components: {CreativeItemInfo},
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    fileData: {
      type: Object,
      default: () => {},
    },
    isAvailable: {
      type: Boolean,
      default: false,
    },
    userIdItems: {
      type: Array,
      default: () => [],
    },
    isCheckLocale: {
      type: Boolean,
      default: false,
    },
    isFileError: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
    basicUrl: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: 'preview.png',
    },
    isShowDaastVast: {
      type: Boolean,
      default: false,
    },
    isViewModerated: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    media: null,
    acceptedType: 'video/mp4, video/webm, video/mov, video/flv, video/avi',
  }),
  computed: {
    isReadonlyWithId() {
      return !!this.id
    },
    isReadonly() {
      return this.isViewModerated
    },
  },
  watch: {
    fileData: {
      deep: true,
      handler(data) {
        this.setFileParams(data)
      },
    },
  },
  methods: {
    onlyNumbers,
    mapRules(name) {
      switch (name) {
        case 'finalUrlRequired':
          return this.isCheckLocale
            ? [
                (v) => !!v || this.$t('rules.finalUrlRequired'),
                (v) => httpRegular.test(v) || this.$t('rules.finalUrlStart'),
              ]
            : []
        default:
          return this.isCheckLocale ? [(v) => !!v || this.$t(`rules.${name}`)] : []
      }
    },
    setFileParams(file) {
      const {filename, w, h, mimeType, duration} = file
      if (filename) {
        this.media = {
          name: filename,
          type: mimeType,
          width: w,
          height: h,
          duration,
          src: `${this.basicUrl}/file/creative/video/stream/${this.token}/${filename}`,
        }
      } else {
        this.media = null
      }
    },
    loadFileParams(file) {
      this.$emit('loadFile', file, 'fileData')
    },
  },
}
</script>

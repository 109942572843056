export const SORT_ITEMS = [
  {
    text: 'Id',
    value: 'id',
  },
  {
    text: 'filter.date',
    value: 'createdAt',
  },
]

export const FILTER_ITEMS = [
  {
    text: 'filter.name',
    name: 'name',
    type: 'string',
  },
  {
    text: 'filter.type',
    name: 'type',
    type: 'select',
    multiple: true,
    isDeep: true,
    isAction: true,
    items: [
      {
        text: 'AUDIO',
        value: 'AUDIO',
      },
      {
        text: 'BANNER',
        value: 'BANNER',
      },
      {
        text: 'VIDEO',
        value: 'VIDEO',
      },
      {
        text: 'NATIVE',
        value: 'NATIVE',
      },
    ],
  },
  {
    text: 'filter.status',
    name: 'status',
    type: 'select',
    items: [
      {
        text: 'IN_USE',
        value: 'IN_USE',
      },
      {
        text: 'NOT_IN_USE',
        value: 'NOT_IN_USE',
      },
    ],
  },
  {
    text: 'filter.isModerated',
    name: 'isModerated',
    type: 'checkbox',
  },
  {
    text: 'filter.userId',
    name: 'userId',
    type: 'server',
    isRole: true,
  },
]

export const TYPE_ITEMS = [
  {
    text: 'AUDIO',
    value: 'AUDIO',
  },
  {
    text: 'BANNER',
    value: 'BANNER',
  },
  {
    text: 'VIDEO',
    value: 'VIDEO',
  },
  {
    text: 'NATIVE',
    value: 'NATIVE',
  },
]

export const NAME_BANNER_ITEMS = [
  {
    text: 'Click',
    value: 'Click',
  },
  {
    text: 'Impression',
    value: 'Impression',
  },
]
